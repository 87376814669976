footer {
  width: 100%;
  padding-top: 32px;
  position: absolute;
  bottom: 0;
  height: 230px;
}

#inner-footer {
  padding: 15px 0;
  background-color: #eee;
}

footer .footer-social {
  padding-top: 25px;
  padding-bottom: 25px;
  background-color: #eee;
}

footer .custom-fa {
  font-size: 36px;
  margin-left: 20px;
  background-color: white;
  color: #d0d0d0;
  border-radius: 51%;
  padding: 10px;
  height: 60px;
  width: 60px;
  text-align: center;
  line-height: 43px;
  text-decoration: none;
  transition: color 0.2s;
}

footer .custom-fa.fa-facebook:hover {
  color: #2b55ff;
}

footer .custom-fa.fa-facebook:focus {
  color: #2b55ff;
}

footer .custom-fa.fa-google-plus:hover {
  color: red;
}

footer .custom-fa.fa-google-plus:focus {
  color: red;
}

footer .custom-fa.fa-twitter:hover {
  color: #00aced;
}

footer .custom-fa.fa-twitter:focus {
  color: #00aced;
}

footer .custom-fa.fa-linkedin:hover {
  color: #1072ae;
}

footer .custom-fa.fa-linkedin:focus {
  color: #1072ae;
}

footer .custom-fa.fa-github:hover {
  color: #292d32;
}

footer .custom-fa.fa-github:focus {
  color: #292d32;
}

@media print {
  footer {
    display: none;
  }
}
