.jumbotron {
  font-family: "Inconsolata", monospace;
  margin-bottom: 0;
}

@media print {
  .navbar {
    display: none;
  }

  .jumbotron {
    padding-bottom: 0px;
    padding-left: 0px;
  }
}
