#content {
  min-height: 100%;
  position: relative;
  padding-bottom: 198px;
}

/* You can add global styles to this file, and also import other style files */
html,
body {
  height: 100%;
}

body {
  font-family: "Lato", sans-serif;
}

@media print {
  div {
    float: none !important;
  }
}
